import React from "react";
import {graphql, Link} from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";

const NoticeLink = ({title, date, author, slug}) => {
    return (
      <Link to={`/information/${slug}`} className="my-1 w-9/12">
        <article className="overflow-hidden p-2 pr-4 bg-gray-400 rounded-l-lg text-right cursor-pointer">
          <h4 className="text-lg text-black">{title}</h4>
          <p className="text-xs text-gray-800">{(new Date(date)).toLocaleString('en-GB', { year: 'numeric', month: 'long', day: 'numeric' })} by {author}</p>
        </article>
      </Link>
    )
}

const Notice = ({ data }) => {
  const {notice, notices: {edges: notices}} = data
  
  const {title, content, dateGmt: date, author: {node: {name: author }}} = notice

  return (
    <Layout headerClass="relative bg-white">
      <SEO title={title}/>
        <div className="flex flex-col md:flex-row mb-8">
          <div className="w-full md:w-2/3 mr-8">
            <header className="w-full mb-6">
              <div className="py-0 mt-6">
                <div className="markdown-body">
                  <h1>{title}</h1>
                </div>
              </div>
              <p className="text-xs text-gray-600 mt-2 mb-4">{date} by {author}</p>
            </header>
            
                <div dangerouslySetInnerHTML={{__html: content}}></div>
        
          </div>
          <div className="w-full md:w-1/3 bg-gray-700 pt-4 pr-2 rounded-r-lg md:rounded-r-none rounded-l-lg mt-8 md:mt-20">
            <div className="grid grid-cols-1 gap-4 justify-items-end">
              <div className="markdown-body w-11/12 text-right"><h2 className={"text-white"}>Other Notices</h2></div>
                {notices && notices.map(({node: {title, dateGmt, slug, author: {node: {name}}}}) => (
                  <NoticeLink title={title} date={dateGmt} author={name} slug={slug} key={slug}/>
                ))}
            </div>
            <div className="text-right">
              <Link className="block py-2 mt-2 font-semibold text-gray-400 bg-transparent focus:outline-none" to={'/information'}>View all <i className="fal fa-angle-double-right"></i></Link></div>
            </div>
        </div>
    </Layout>
  )
}

export default Notice

export const query = graphql`
    query noticeQuery ($slug: String) {
        notice: wpPost(slug: {eq: $slug}) {
            author {
                node {
                    name
                }
            }
            dateGmt
            slug
            title
            content
            tags {
                nodes {
                    name
                    uri
                }
            }
        },
        notices: allWpPost(sort: {order: DESC, fields: date}, filter: {categories: {nodes: {elemMatch: {name: {regex: "", glob: "notice"}}}}, slug: {ne: $slug}}, limit: 8) {
            edges {
                node {
                    title
                    dateGmt
                    slug
                    author {
                        node {
                            name
                        }
                    }
                    tags {
                        nodes {
                            name
                            uri
                        }
                    }
                }
            }
        }
    }
`;
